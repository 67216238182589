const animatedScrollObserver = new IntersectionObserver(
  (entries, animatedScrollObserver) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        if (entry.target.getAttribute('animation-type') == 1) {
          entry.target.classList.add('toolbarActive');
        } else if (entry.target.getAttribute('animation-type') == 2) {
          entry.target.classList.add('enter2');
        }
        animatedScrollObserver.unobserve(entry.target);
      }
    });
  }
);

export default {
  bind(el, binding) {
    var x = '';
    if (binding.value.mode == 1) {
      x = 'before-enter';
      el.setAttribute('animation-type', '1');
    } else if (binding.value.mode == 2) {
      x = 'before-enter2';
      el.setAttribute('animation-type', '2');
    }
    el.classList.add(x);

    animatedScrollObserver.observe(el);
  }
};
