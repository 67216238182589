import Vue from 'vue';

import './styles/quasar.sass';
import '@quasar/extras/material-icons/material-icons.css';
import {
  Quasar,
  QLayout,
  QHeader,
  QDrawer,
  QPageContainer,
  QPage,
  QToolbar,
  QToolbarTitle,
  QBtn,
  QIcon,
  QList,
  QItem,
  QItemSection,
  QItemLabel,
  QCard,
  QCardSection,
  QCardActions,
  QCarousel,
  QCarouselControl,
  QCarouselSlide,
  QImg,
  QBreadcrumbs,
  QBreadcrumbsEl,
  QInput,
  QSeparator,
  QSpace,
  Loading,
  Notify,
  QBtnDropdown,
  QMenu,
  ClosePopup,
  QDialog
} from 'quasar';

Vue.use(Quasar, {
  config: {},
  components: {
    QLayout,
    QHeader,
    QDrawer,
    QPageContainer,
    QPage,
    QToolbar,
    QToolbarTitle,
    QBtn,
    QIcon,
    QList,
    QItem,
    QItemSection,
    QItemLabel,
    QCard,
    QCardSection,
    QCardActions,
    QCarousel,
    QCarouselControl,
    QCarouselSlide,
    QImg,
    QBreadcrumbs,
    QBreadcrumbsEl,
    QInput,
    QSeparator,
    QSpace,
    QBtnDropdown,
    QMenu,
    QDialog
  },
  directives: {
    ClosePopup
  },
  plugins: {
    Notify,
    Loading
  }
});
