import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './quasar';
import VueScrollTo from 'vue-scrollto';
import ScrollAnimation from './directives/scrollanimation';
import VueMeta from 'vue-meta';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import dotenv from 'dotenv';
import { config } from './config/config';

dotenv.config();

Vue.use(VueScrollTo);
Vue.directive('scrollanimation', ScrollAnimation);
Vue.use(VueMeta);
Vue.use(VueReCaptcha, { siteKey: config.siteKey });

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
