<template>
  <q-layout view="lHh Lpr lFf">
    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script>
export default {
  name: 'LayoutDefault',
  data() {
    return {
      leftDrawerOpen: false
    };
  }
};
</script>

<style>
.grecaptcha-badge {
  visibility: hidden;
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');

html,
body {
  font-family: 'Raleway', 'Montserrat', sans-serif !important;
}

#app {
  font-family: 'Raleway', 'Montserrat', sans-serif !important;
}
</style>
